@import "components/variables"
@import "components/mixins"

.overlayClassName
  background-color: rgba(255, 255, 255, 0.75)
  position: fixed
  width: 100%
  height: 100vh
  overflow-y: auto
  top: 0
  bottom: 0
  z-index: 1000
  display: flex
  justify-content: center
  align-items: center
  align-content: center

.modal
  border: 1px solid #ede9e9
  background: #FCFCFC
  outline: none
  padding: 20px
  border-radius: 8px
  overflow: visible
  max-width: 80%

\:global
  .ReactModal__Overlay 
    opacity: 0
    transition: opacity 200ms ease-in-out

  .ReactModal__Overlay--after-open
    opacity: 1
  
  .ReactModal__Overlay--before-close
    opacity: 0