@import "components/variables"
@import "components/mixins"

.buttons
  display: flex
  justify-content: center
  align-content: center
  align-items: center

  button
    @include button-default
    height: 30px
    padding: 0 3px
    margin: 1px
    border-radius: 5px
    font-size: 12px
    font-weight: 500

.header
  margin-top: 20px
  display: flex
  justify-content: center
  align-content: center
  align-items: center

  button
    top: auto !important