@import "components/variables"
@import "components/mixins"

.addNewToken
  display: flex
  .button
    @include button-default
    margin: 0
    padding: 0
    width: 40%
    margin-left: 10px
    min-width: 140px

  input
    @include button-default
    width: 60%
    cursor: auto
    background: $gray-light
    color: $black
    margin: 0
    padding: 0
    width: 100%
    height: 44px
    font-size: 13px

    &:hover
      background: $border-color