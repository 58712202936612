@import "components/variables"

.content
  display: flex
  flex-direction: column
  align-content: center
  justify-content: center
  align-items: center

  .chart
    width: 80%

    @media screen and (max-width: $tablet)
      width: 100%

