@import "components/variables"

.content
  width: 100%
  display: flex
  margin-top: 10px
  flex: 1
  justify-content: center

  @media screen and (max-width: $tablet)
    flex-direction: column

  .totalBalance
    display: flex
    justify-content: center
    width: 34%
    border-right: 1px solid $border-color

    &.alarm
      color: $red


    @media screen and (max-width: $tablet)
      width: 100%
      border: 0
      border-bottom: 1px solid $border-color

  .income
    display: flex
    justify-content: center
    width: 33%
    border-right: 1px solid $border-color

    @media screen and (max-width: $tablet)
      width: 100%
      border: 0
      border-bottom: 1px solid $border-color

  .outcome
    display: flex
    justify-content: center
    width: 33%

    @media screen and (max-width: $tablet)
      width: 100%
      border: 0