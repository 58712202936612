.amountMulti
  width: 100%
  display: flex
  flex-direction: column

  .header
    width: 100%
    display: flex
    justify-content: center

  .content
    width: 100%
    display: flex
    justify-content: space-between

    .amountGte
      display: flex
      align-items: center
      width: 49%

      input
        width: 100%

    .amountLte
      display: flex
      align-items: center
      width: 49%

      input
        width: 100%