@import "components/variables"
.userAvatar
  border-radius: 50%
  width: 25px
  height: 25px
  display: flex
  justify-content: center
  align-items: center
  font-size: 15px
  color: $white
  text-decoration: none
  overflow: hidden

  img
    display: block
    max-width: 100%
    max-height: 100%