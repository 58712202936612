@import "components/variables"
@import "components/mixins"

.accountInfo

  .header
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center

    .title
      font-size: 20px
      font-weight: 600
      line-height: 1.6
      letter-spacing: -.02em

      @media screen and (max-width: $tablet)
        font-size: 13px

  .content
    width: 100%
    margin-top: 10px
    display: flex
    flex-direction: column
    align-items: center

    .info
      width: 100%

      .showHintsButton
        @include button-default

    .button
      @include button-default
      min-width: 160px