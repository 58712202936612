@import "components/variables"

.table
  display: flex
  height: 100%
  font-style: normal
  justify-content: center
  width: 100%
  
.tableContainer
  display: flex
  flex-direction: column
  box-sizing: border-box
  height: 100%
  width: 100%

.flexTable
  display: flex
  flex-flow: row wrap
  border-bottom: 1px solid $border-color

  &.category
    min-height: 34.8px

    @media screen and (max-width: $tablet)
      min-height: 208.8px


  &:hover
    background: $gray-light

  &:last-child
    border: none

.indexCol
  order: 1
  width: 4%
  color: $gray

  @media screen and (max-width: $tablet)
    box-sizing: border-box
    width: 100% !important

.enumTypeCol
  order: 2
  width: 10%

  @media screen and (max-width: $tablet)
    box-sizing: border-box
    width: 100% !important

  &.income
    font-weight: bolder
    color: $green 

  &.outcome
    font-weight: bolder
    color: $red 

  span
    color: $black

.titleCol
  order: 3
  width: 36%

  @media screen and (max-width: $tablet)
    box-sizing: border-box
    width: 100%

.colorCol
  order: 4
  width: 15%
  display: flex
  justify-content: center

  .colorBlock
    width: 50%
    border-radius: $border-radius

    @media screen and (max-width: $tablet)
      width: 20%
      border-radius: 3px

  @media screen and (max-width: $tablet)
    width: 100%
    justify-content: start

.createdCol
  order: 5
  width: 15%

  @media screen and (max-width: $tablet)
    width: 100%

.buttonsCol
  order: 6
  width: 10%
  display: flex
  justify-content: center

  button
    background: inherit
    border: 0
    
    &:not(:first-child)
      margin-left: 10px

    @media screen and (max-width: $tablet)
      &:not(:first-child)
        margin-left: 50px
        
      svg
        height: 25px !important
        width: 25px !important

    &:hover
      cursor: pointer

  @media screen and (max-width: $tablet)
    box-sizing: border-box
    width: 100%

.flexRow
  text-align: center
  padding: 5px 5px

  @media screen and (max-width: $tablet)
    text-align: left

  span
    display: none

    @media screen and (max-width: $tablet)
      display: inline-block
      font-weight: bolder

.categoriesList
  overflow: hidden
  overflow-y: auto
  height: 83%
  
  @media screen and (max-width: $tablet)
    height: 91%


.primaryText
  font-weight: bold
  line-height: 23px
  font-size: 15px
  color: black

  @media screen and (max-width: $tablet)
    font-size: 12px
    line-height: 20px

.header
  padding-bottom: 12px
  font-weight: normal
  font-size: 17px
  line-height: 20px
  color: black
  opacity: 0.5

  .ordering
    cursor: pointer
    display: flex
    justify-content: center

    svg
      height: 23px
      margin-left: 3px

  @media screen and (max-width: $tablet)
    padding-bottom: 2px
    display: flex
    width: 100%

    .indexCol, .colorCol, .titleCol, .createdCol
      display: none


    .ordering
      font-size: 13px
      width: 100% !important
      display: flex
      justify-content: center
      text-align: center
      align-items: center


  &:hover
    background: inherit