@import "components/variables"

@mixin button-default
    height: 48px
    padding: 0 10px
    margin: 10px
    border-radius: $border-radius
    border: 2px solid transparent
    font-size: 15px
    font-weight: 600
    line-height: 1.6
    color: $black
    text-align: center
    cursor: pointer
    background: $blue
    color: $white
    transition: all .2s ease

    &:hover
        background-color: rgba($blue, $hover-opacity)
    
@mixin modal-default
    position: absolute
    inset: 40px
    border: 1px solid $border-color
    background: $white
    overflow: auto
    outline: none
    padding: 20px
    border-radius: $border-radius
    margin: auto
    max-width: 400px
    max-height: 510px
    overflow: visible