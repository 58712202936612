@import "components/variables"
@import "components/mixins"

.transactions
  @media screen and (max-width: $tablet)
    min-height: 350px

  .header
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center

    .title
      font-size: 20px
      font-weight: 600
      line-height: 1.6
      letter-spacing: -.02em

      @media screen and (max-width: $tablet)
        font-size: 13px

    .rightHeader
      display: flex
      align-items: center

      .transactionsFilter
        @include button-default
        height: auto
        margin: 0
        padding: 3px 10px
        display: flex

        svg
          height: 20px
          width: 20px
          display: flex
          fill: $white

  .content
    width: 100%
    display: flex
    margin-top: 10px
    flex: 1
    justify-content: center
    flex-direction: column

    @media screen and (max-width: $tablet)
      flex-direction: column

    .allTransactions
      display: flex
      justify-content: center

      a
        @include button-default
        margin: 0
        margin-top: 5px
        display: flex
        justify-content: center
        align-items: center
        text-decoration: none