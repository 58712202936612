@import "components/variables"
  
.pieChart
  width: 100%
  @media screen and (max-width: $tablet)
    min-height: 350px
    
  .header
    width: 100%
    display: flex
    justify-content: space-between

    .title
      font-size: 20px
      font-weight: 600
      line-height: 1.6
      letter-spacing: -.02em
      width: 30%

      @media screen and (max-width: $tablet)
        font-size: 13px

    .filters
      display: flex
      width: 70%
      justify-content: flex-end
      
      @media screen and (max-width: $tablet)
        flex-direction: column

  .content
    width: 100%
    display: flex
    margin-top: 10px
    flex: 1
    justify-content: center