@import "components/variables"
@import "components/mixins"
  
.savings

  input
    @include button-default
    cursor: auto
    background: $gray-light
    color: $black
    margin: 0
    padding: 0
    width: 100%
    height: 40px
    font-size: 13px
    width: 50px

    &:hover
      background: $border-color