$mobile: 760px
$tablet: 1020px
$desktop: 99999px

$border-radius: 8px
$hover-opacity: 0.85

$gray-light: #F4F4F4
$gray: #868484
$border-color:#ede9e9
$white: #FCFCFC
$black: #1A1D1F
$red: #de5555
$green: #5dbc3b
$blue: #2A85FF