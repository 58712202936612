.content
  display: flex
  flex-direction: column
  align-content: center
  justify-content: center
  align-items: center

  .dates
    font-size: 15px
    font-weight: 400
    line-height: 1.6
    letter-spacing: -.02em

  .chart
    width: 350px
