@import "components/variables"
  
.balanceDynamic
  width: 100%
    
  .header
    width: 100%
    display: flex
    justify-content: space-between

    .title
      font-size: 20px
      font-weight: 600
      line-height: 1.6
      letter-spacing: -.02em
      display: flex
      align-items: center

      @media screen and (max-width: $tablet)
        font-size: 13px
        
    .filters
      display: flex
        
  .content
    width: 100%
    display: flex
    margin-top: 10px
    flex: 1
    justify-content: center