@import "components/variables"
  
::-webkit-scrollbar 
  width: 6px
  height: 6px

::-webkit-scrollbar-track 
  border-radius: $border-radius
  background: rgba(0,0,0,0.1)

::-webkit-scrollbar-thumb
  border-radius: $border-radius
  background: rgba(0,0,0,0.2)

  &:hover
    background: rgba(0,0,0,0.4)

  &:active
    background: rgba(0,0,0,.9)

  
.content
  min-width: 300px
  background: $gray-light
  display: flex
  flex-direction: column
  flex: 4
  overflow-y: auto
  overflow-x: hidden
  padding: 0 24px