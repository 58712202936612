@import "components/variables"
@import "components/mixins"

.sign
  width: 22px
  height: 22px
  display: flex
  justify-content: center
  align-items: center
  border-radius: 50%
  background-color: $blue
  cursor: pointer
  color: $white
  margin: 0 3px 0 3px
  font-size: 16px

  &:hover
    background-color: rgba($blue, $hover-opacity)


.container
  max-width: 60%

.hintContainer
  margin: 0 10px 0 10px
  display: flex
  justify-content: center
  align-items: center
  align-content: center
  flex-direction: column
  font-size: 14px
  background-color: $white
  box-shadow: 0 0 4px rgba(160, 20, 142, 0.17)
  color: $black
  padding: 5px
  border-radius: 2px

.info
  display: flex
  justify-content: center
  align-items: center
  align-content: center
  border-top: 1px solid $border-color
  width: 100%