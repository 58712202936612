@import "components/variables"
@import "components/mixins"
  
.input
  @include button-default
  cursor: auto
  background: $gray-light
  color: $black
  margin: 0
  padding: 0
  width: 100%
  height: 40px
  font-size: 13px

  &:hover
      background: $border-color

  @media screen and (max-width: $tablet)
    font-size: 10px