@import "components/variables"

body
  margin: 0

.main
  display: flex
  flex-direction: row
  height: 100vh

  .sidebar
    background: $white
    flex: 1
    padding: 24px
  
  .pageInner
    display: flex
    flex-direction: column
    flex: 5
    overflow: hidden
    