@import "components/variables"
@import "components/mixins"

.transactionsBlock
  max-height: 100%
  max-height: stretch
  display: flex
  padding: 24px 3px 24px 24px

.transactions
  height: 100%
  display: flex
  flex-direction: column

  .header
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center

    .title
      font-size: 20px
      font-weight: 600
      line-height: 1.6
      letter-spacing: -.02em

      @media screen and (max-width: $tablet)
        font-size: 13px

      
    .rightHeader
      display: flex
      align-items: center

      .count
        margin-right: 10px

      .transactionsFilter
        @include button-default
        height: auto
        margin: 0
        margin-right: 21px
        padding: 3px 10px
        display: flex

        svg
          height: 20px
          width: 20px
          display: flex
          fill: $white
      

  .content
    width: 100%
    height: 100%
    margin-top: 10px
    display: flex
    flex-direction: column
    align-items: center

    @media screen and (max-width: $tablet)
      font-size: 13px
      margin-top: 2px