@import "components/variables"

.periodStatistic
  @media screen and (max-width: $tablet)
    min-height: 350px

  .header
    width: 100%
    display: flex
    justify-content: space-between

    .title
      font-size: 20px
      font-weight: 600
      line-height: 1.6
      letter-spacing: -.02em
      display: flex
      align-items: center

      @media screen and (max-width: $tablet)
        font-size: 13px
    
    .filters
      display: flex