@import "components/variables"

.header
  width: 100%
  display: flex
  justify-content: space-between
  align-items: center

  .title
    font-size: 20px
    font-weight: 600
    line-height: 1.6
    letter-spacing: -.02em
    display: flex
    align-items: center

    span
      margin-right: 3px

    @media screen and (max-width: $tablet)
      font-size: 13px

  .rightHeader
    display: flex
    align-items: center