@import "components/variables"
@import "components/mixins"
  
.deleteTransaction
    background: $white
    border-radius: $border-radius
    display: flex
    flex-direction: column

    .title
        text-align: center

    .transaction
        display: flex
        width: 100%
        flex-direction: column

        .field
            
            span
                color: $black
                font-weight: bolder

            &.income
                font-weight: bolder
                color: $green 

            &.outcome
                font-weight: bolder
                color: $red
    
    .buttons
        display: flex
        width: 100%

        button
            padding: 0 !important
            margin: 3px !important
            width: 50% !important
            @include button-default

        .accept
            background-color: $red

            &:hover
                background-color: rgba($red, $hover-opacity)
                        

        

