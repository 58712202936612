@import "components/mixins"
@import "components/variables"

.addTransaction

  button
    @include button-default
    width: 90px
    font-size: 30px
    line-height: 30px

  .income
    background-color: $green

    &:hover
      background-color: rgba($green, $hover-opacity)

  .outcome
    background-color: $red
    
    &:hover
      background-color: rgba($red, $hover-opacity)