@import "components/variables"

nav
  @media screen and (max-width: $mobile) 
    padding: 0 24px

  ul
    list-style: none
    padding: 0
    margin: 0
    width: 100%
