@import "components/variables"

.table
  display: flex
  height: 100%
  font-style: normal
  justify-content: center
  width: 100%
  
.tableContainer
  display: flex
  flex-direction: column
  box-sizing: border-box
  height: 100%
  width: 100%

.flexTable
  display: flex
  flex-flow: row wrap
  border-bottom: 1px solid $border-color

  &.transaction
    min-height: 34.8px

    @media screen and (max-width: $tablet)
      min-height: 208.8px


  &:hover
    background: $gray-light

  &:last-child
    border: none

.indexCol
  order: 1
  color: $gray
  width: 4%


.typeCol
  order: 2
  width: 10%

  &.income
    font-weight: bolder
    color: $green 

  &.outcome
    font-weight: bolder
    color: $red 

  span
    color: $black


.amountCol
  order: 3
  width: 16%

  @media screen and (max-width: $tablet)
    &:after
      content: ' р.'

.creatorCol
  order: 4
  width: 6%
  display: flex
  align-items: center
  justify-content: center

  @media screen and (max-width: $tablet)
    justify-content: flex-start

.categoryCol
  order: 5
  width: 18%

  .status
    display: inline-block


.dateCol
  order: 6
  width: 15%

.titleCol
  order: 7
  width: 12%

.buttonsCol
  order: 8
  width: 8%
  display: flex
  justify-content: center

  button
    background: inherit
    border: 0
    
    &:not(:first-child)
      margin-left: 10px

    @media screen and (max-width: $tablet)
      &:not(:first-child)
        margin-left: 50px
        
      svg
        height: 25px !important
        width: 25px !important

    &:hover
      cursor: pointer


.hideIndexCol
  .indexCol
    display: none
  .categoryCol
    width: 22%

.hideCreatorCol
  .creatorCol
    display: none
  .categoryCol
    width: 22%
  .amountCol
    width: 18%
  
.hideIndexAndCreatorCol
  .indexCol
    display: none
  .creatorCol
    display: none
  .categoryCol
    width: 22%
  .amountCol
    width: 18%
  .titleCol
    width: 16%


.flexRow
  text-align: center
  padding: 5px 5px

  @media screen and (max-width: $tablet)
    text-align: left
    width: 100% !important
    box-sizing: border-box

  span
    display: none

    @media screen and (max-width: $tablet)
      display: inline-block
      font-weight: bolder

.transactionsList
  overflow: hidden
  overflow-y: auto
  height: 86%
  
  @media screen and (max-width: $tablet)
    height: 91%


.primaryText
  font-weight: bold
  line-height: 23px
  font-size: 15px
  color: black

  @media screen and (max-width: $tablet)
    font-size: 12px
    line-height: 20px

.header
  padding-bottom: 12px
  font-weight: normal
  font-size: 17px
  line-height: 20px
  color: black
  opacity: 0.5

  .ordering
    cursor: pointer
    display: flex
    justify-content: center

    svg
      height: 23px
      margin-left: 3px

  @media screen and (max-width: $tablet)
    padding-bottom: 2px
    display: flex
    width: 100%

    .indexCol, .categoryCol, .typeCol, .titleCol, .buttonsCol, .creatorCol
      display: none

    .amountCol, .dateCol
      font-size: 13px
      width: 48% !important
      display: flex
      justify-content: center

      &:after
        content: none

  &:hover
    background: inherit