@import "components/variables"
@import "components/mixins"
  
.form
    background: $white
    border-radius: $border-radius
    display: flex
    flex-direction: column

    > div
        margin-top: 5px

    input, select
        @include button-default
        cursor: auto
        background: $gray-light
        color: $black
        margin: 0
        padding: 0
        height: 40px

        &:hover
            background: $border-color

    .button
        @include button-default
        padding: 0
        width: 100%
        margin: 0
        margin-top: 8px

        &.set
            background: $green 

            &:hover
                background-color: rgba($green, $hover-opacity)