@import "components/variables"
  
.header
  display: flex
  min-width: 300px
  align-items: center
  padding: 7px 5px
  background: $white
  box-shadow: inset 1px 0px 0px $gray-light, inset 0 -1px 0px $gray-light
  justify-content: flex-end
  min-height: 62px

  @media screen and (max-width: $mobile)
    justify-content: space-between