@import "components/variables"
@import "components/mixins"
  
.deleteCategory
    background: $white
    border-radius: $border-radius
    display: flex
    flex-direction: column

    .title
        text-align: center

    .category
        display: flex
        width: 100%
        flex-direction: column

        .field
            width: 100%

            &.income
                font-weight: bolder
                color: $green 

            &.outcome
                font-weight: bolder
                color: $red

        .color
            display: flex
            width: 100%

            .colorBlock
                width: 20%
                border-radius: 3px

            
        span
            color: $black
            font-weight: bolder

    .buttons
        display: flex
        width: 100%

        button
            padding: 0 !important
            margin: 3px !important
            width: 50% !important
            @include button-default

        .accept
            background-color: $red

            &:hover
                background-color: rgba($red, $hover-opacity)
                        

        

