@import "components/variables"
@import "components/mixins"

.tinkoffInvest
  width: 100%
  display: flex
  flex-direction: column

  .header
    display: flex
    align-items: center

    .title  
      font-size: 16px
      font-weight: 600

      @media screen and (max-width: $tablet)
        font-size: 12px
    
    .balance
      display: flex
      flex-direction: column

.button
  @include button-default
  min-width: 160px